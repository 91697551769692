import React, { useState } from "react";
import { navigate } from "gatsby";
import styled from "styled-components";
import PropTypes from "prop-types";
import useGlobal from "../store";
import Page from "../components/Page";
import Container from "../components/styles/Container";
import Heading from "../components/styles/Heading";
import Box from "../components/styles/Box";
import Input from "../components/styles/LargeInput";
import FormBanner from "../components/styles/FormBanner";
import SubmitButton from "../components/styles/SubmitButton";
import { onEnter } from "../utils/helpers";
import Subhead from "../components/styles/Subhead";

const RegistrationPage = ({ location }) => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [isSignUp, setIsSignUp] = useState("");
  const [, actions] = useGlobal();
  const { signUp } = actions;

  const register = async () => {
    await signUp({
      username,
      password,
      email,
      first_name: firstName,
      last_name: lastName
    });
  };

  const submitDisabled = () => {
    if (!username || !password) {
      return true;
    } else if (password !== passwordConfirm) {
      return true;
    }
    return false;
  };

  const PasswordMessage = styled.em`
    display: inline-block;
    color: #a94442;
    font-size: 1em;
    text-align: right;
    width: 250px;
  `;

  const RedirectContainer = styled.div`
    display: grid;
    justify-content: center;
    grid-gap: 20px;
  `;

  const RedirectHeader = styled(Subhead)`
    color: #828f98;
    text-align: center;
  `;

  return (
    <Page>
      {isSignUp ? (
        <FormBanner>
          <Container>
            <Box py="50px">
              <Heading my={3} fontSize={6} mx="auto">
                Register
              </Heading>
              <br />
              <Input
                id="firstName"
                onChange={e => setFirstName(e.target.value)}
                onKeyUp={e => onEnter(e, register)}
                placeholder="Enter first name"
                value={firstName}
                color="black"
                width="250px"
              />
              <br />
              <br />
              <Input
                id="lastName"
                onChange={e => setLastName(e.target.value)}
                onKeyUp={e => onEnter(e, register)}
                placeholder="Enter last name"
                value={lastName}
                color="black"
                width="250px"
              />
              <br />
              <br />
              <Input
                id="email"
                onChange={e => setEmail(e.target.value)}
                onKeyUp={e => onEnter(e, register)}
                placeholder="Enter email"
                value={email}
                type="email"
                color="black"
                width="250px"
              />
              <br />
              <br />
              <Input
                id="username"
                onChange={e => setUsername(e.target.value)}
                onKeyUp={e => onEnter(e, register)}
                placeholder="Enter username"
                value={username}
                color="black"
                width="250px"
              />
              <br />
              <br />
              <Input
                id="password"
                onChange={e => setPassword(e.target.value)}
                onKeyUp={e => onEnter(e, register)}
                match={password === passwordConfirm}
                placeholder="Enter password"
                value={password}
                type="password"
                color="black"
                width="250px"
              />
              {password !== passwordConfirm && (
                <PasswordMessage>passwords don&#39;t match</PasswordMessage>
              )}
              <br />
              <br />
              <Input
                id="passwordConfirm"
                onChange={e => setPasswordConfirm(e.target.value)}
                onKeyUp={e => onEnter(e, register)}
                match={password === passwordConfirm}
                placeholder="Re enter password"
                value={passwordConfirm}
                type="password"
                color="black"
                width="250px"
              />
              {password !== passwordConfirm && (
                <PasswordMessage>passwords don&#39;t match</PasswordMessage>
              )}

              <br />

              <Box my={6}>
                <SubmitButton disabled={submitDisabled()} onClick={register}>
                  Submit
                </SubmitButton>
              </Box>
            </Box>
          </Container>
        </FormBanner>
      ) : (
        <RedirectContainer>
          <RedirectHeader>CHOOSE AN OPTION</RedirectHeader>
          <SubmitButton onClick={() => setIsSignUp(true)}>
            NEW USER
          </SubmitButton>
          <SubmitButton onClick={() => navigate(`/login${location.search}`)}>
            RETURNING USER
          </SubmitButton>
        </RedirectContainer>
      )}
    </Page>
  );
};

RegistrationPage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired
};

export default RegistrationPage;
